import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
// import { DiscussionEmbed } from "disqus-react";
import SEO from "../components/seo";
import RecentBlogRoll from "../components/RecentBlogRoll";
import Subscribe from "../components/Subscribe";
// import "@fontsource/poppins";

// import "prismjs/plugins/custom-class/custom-class.js";

const BlogPostTemplate = ({
  image,
  id,
  tableOfContents,
  timeToRead,
  slug,
  content,
  contentComponent,
  description,
  tags,
  date,
  title,
  author,
  authorurl,
  recentPosts,
}) => {
  const PostContent = contentComponent || Content;

  // Prism.plugins.CustomClass.prefix("prism--");

  // Prism.plugins.customClass.prefix("prism--");

  // useEffect(() => {
  //   import("@deckdeckgo/highlight-code/dist/loader").then((module) => {
  //     const deckDeckGoHighlightElement = module.defineCustomElements;
  //     deckDeckGoHighlightElement();
  //   });
  // }, []);
  // const disqusConfig = {
  //   shortname: "khannida",
  //   config: { identifier: slug, title, id: title },
  // };

  return (
    <article className="slide-up">
      <div className="content blog">
        <div className="flex flex-col-around">
          <div className="blogContainer article-and-sidebar">
            <section className="blog-main-section">
              <h1 className="blog-header-title">{title}</h1>

              <p className="blogPostedDetails">
                Posted by{" "}
                <a
                  href={authorurl}
                  style={{ color: "inherit" }}
                  className="blogPostAuthor"
                >
                  <i>{author}</i>
                </a>{" "}
                on{" "}
                <span className="blogPostDate">
                  {date} &bull; {timeToRead} min
                </span>{" "}
              </p>
              <p className="blogPostDescription">{description}</p>
              <div className="line"></div>

              <div className="blogPostContentsContainer">
                <div className="toc menu">
                  <p className="menu-label">Table of Contents</p>
                  <div dangerouslySetInnerHTML={{ __html: tableOfContents }} />
                </div>
                <PostContent className="blogPostContent" content={content} />
                {tags && tags.length ? (
                  <div style={{ margin: "4rem 0rem" }}>
                    <h4>Tags</h4>
                    <ul className="taglist">
                      {tags.map((tag) => (
                        <li
                          key={tag + `tag`}
                          className="post-tag is-primary center"
                          //  style={{ color: "white" }}
                        >
                          {/* <Link
                              className="tag is-primary"
                              to={`/tags/${kebabCase(tag)}/`}
                            > */}
                          {tag}
                          {/* </Link> */}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>

              <Subscribe />
              {/* <div class="sharethis-inline-reaction-buttons"></div> */}
              <div>
                <h4 className="recent-posts">Recent Blog Posts</h4>
                <div className="grid grid-4 recent-blog-grid">
                  <RecentBlogRoll recentPosts={recentPosts} />
                </div>
              </div>
              {/* <DiscussionEmbed {...disqusConfig} /> */}
            </section>

            <aside className="is-narrow-mobile is-fullheight is-hidden-mobile sidebar">
              <div>
                <h4 className="recent-posts">Recent Blog Posts</h4>
                <RecentBlogRoll recentPosts={recentPosts} />
              </div>
            </aside>
          </div>
        </div>
      </div>
    </article>
  );
};

// const scrollToBlog = () => {
//   if (typeof window !== "undefined") {
//     window.scrollTo({ top: 400, behavior: "smooth" });
//   }
// };

BlogPostTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.node.isRequired,
  date: PropTypes.string,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const { allMarkdownRemark: recentPosts } = data;

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt || "nothing"}
        image={
          post.frontmatter.featuredimage.childImageSharp.gatsbyImageData.images
            .fallback.src
        }
        pathname={post.fields.slug}
        canonicalurl={post.frontmatter.canonicalurl}
        article
      />
      <BlogPostTemplate
        content={post.html}
        id={post.id}
        tableOfContents={post.tableOfContents}
        timeToRead={post.timeToRead}
        slug={post.fields.slug}
        image={post.frontmatter.featuredimage}
        contentComponent={HTMLContent}
        date={post.frontmatter.date}
        description={post.frontmatter.description}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        author={post.frontmatter.author}
        authorurl={post.frontmatter.authorurl}
        recentPosts={recentPosts}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      timeToRead
      tableOfContents
      fields {
        slug
      }
      frontmatter {
        date(formatString: "DD MMM, YYYY")
        title
        description
        canonicalurl
        author
        authorurl
        featuredimage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            id: id
          }
        }
        tags
      }
    }

    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" } }
        id: { ne: $id }
      }
      limit: 6
    ) {
      edges {
        node {
          timeToRead
          excerpt(pruneLength: 100)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            description
            date(formatString: "DD MMM, YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
