import React from "react";
import { Link } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

class RecentBlogRoll extends React.Component {
  render() {
    const { edges: posts } = this.props.recentPosts;

    return (
      <>
        {posts &&
          posts.map(({ node: post }) => (
            <div className="recent-blog-roll is-12" key={post.id}>
              <Link to={post.fields.slug}>
                <article
                  className={`blog-list-item ${
                    post.frontmatter.featuredpost ? "is-featured" : ""
                  }`}
                >
                  <header className="flex flex-col-around recentBlog">
                    {post.frontmatter.featuredimage ? (
                      <div className="featured-thumbnail">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          }}
                        />
                      </div>
                    ) : null}

                    <p className="post-meta">
                      <span className="subtitle is-block blogRollDate">
                        {post.frontmatter.date} &bull; {post.timeToRead} min
                      </span>
                      <span
                        role="link"
                        className="has-text-primary is-size-6 recent-blog-title recentBlogTitle"
                      >
                        {post.frontmatter.title}
                      </span>
                    </p>
                  </header>
                </article>
              </Link>
            </div>
          ))}
      </>
    );
  }
}

// const ClipText = (data, length) => {
//   return data.slice(0, length) + "...";
// };

export default RecentBlogRoll;
