import React, { Component } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

export class Subscribe extends Component {
  constructor() {
    super();
    this.state = { result: null };
  }

  _handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(e.target.email.value);
    this.setState({ result: result });
  };

  render() {
    return (
      <div className="newsletter-form card">
        <form onSubmit={this._handleSubmit} method="post">
          <div className="label">
            Get the latest updates directly in your mailbox.
          </div>
          <div className="label">Subscribe to my Newsletter!</div>

          <div className="field has-addons">
            <p className="control has-icons-left">
              <input
                className="input is-success"
                type="email"
                name="email"
                placeholder="Enter your email"
              />
              <span className="icon is-normal is-left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z" />
                </svg>
                {/* <i className="fas fa-envelope"></i> */}
              </span>
            </p>

            <button className="button is-primary" type="submit">
              <span className="icon is-normal is-left">
                {/* <i className="fas fa-paper-plane"></i> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z" />
                </svg>
              </span>
              <strong>Subscribe</strong>
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default Subscribe;
